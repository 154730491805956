@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
    --primary-color: #f5f5f5;
    --dark-color: #333;
    font-family: "Montserrat", sans-serif !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


.body {
    @apply bg-gray-100;
    /* font-family: 'Poppins', sans-serif; */
    min-height: 100vh;
    color: var(--dark-color);
    background-color: #fff;
    font-family: "Montserrat", sans-serif !important;
}
.Hero{
    font-family: "Montserrat", sans-serif !important;
}
.platformSection{
    background: linear-gradient(45deg, rgb(247, 241, 222), rgb(255, 255, 255));
}

.hero {
    /* background: url('../assets/landing-hero.jpg') no-repeat center center/cover; */
    color: var(--primary-color);
    min-height: 100vh;
    width: 100%;

}



.hero .overlay {
    @apply text-gray-100;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    background-color: #fff;
    /* background: rgba(2, 92, 72, 0.8); */
    /* background: linear-gradient(45deg, rgb(247, 241, 222), rgb(255, 255, 255)); */


    .content {
        min-height: calc(100vh - 70px);
    }
}
@media (max-width: 768px) {

    .hero .overlay,
    header {
        margin-top: 0px;
    }
    .mobileNav{
        display: flex;
    }

}