

@media only screen and (max-width: 767px){
    .cardContainer{
        display: flex;
        flex-direction: column;
    }
    .card{
        width: 100% !important;
    }
    }


    @media only screen and (min-width: 768px) and (max-width: 1024px){
        .cardContainer{
            display: flex;
            flex-direction: column;
        }
        .card{
            width: 100% !important;
        }
    }