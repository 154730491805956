
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.sideMenuHover:hover{
  background-color: #414140ab !important;
  color: #fff !important;
}

.m_539e827b:where([data-active]) {
  border-color: #f4c508 !important;
  background-color: #000 !important;
  color: #fff !important;
}

#landing-hero {
  position: relative;
  background-image: url('./assets/abstract.webp');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; /* Text color for visibility */
  overflow: hidden;
}

#landing-hero::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Adjust the opacity for a lighter or darker overlay */
  z-index: 1;
}

#landing-hero > * {
  position: relative;
  z-index: 2; /* Ensure content appears above the overlay */
}


.dashboard-container {
  background-color: #eaeaea;
  display: flex;
 justify-content: center;
 margin: 0 auto;
}

.dashboard-page-container{
  width: 100%;
}
:root{
  font-size: 15px;
}


*{
font-family: "Montserrat", sans-serif ;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  box-sizing: border-box;

 
  /* font-family: "Lato", sans-serif !important;
  font-weight: 500;
  font-style: normal; */
}

textarea:focus, input:focus{
  outline: none;
}

input{
  width: 100%;
  height: 35px;
  padding-inline:10px ;
  border: none;
  /* background-color: #dedede; */
  margin-block: 5px;
  border-radius: 4px;
}

.inputText{
  background-color: #f7f7f702;
  width: 100%;
  padding: 0;
  margin: 0;
  height: 35px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #d1d1d1;
  /* margin-bottom: 10px; */
  border-radius: 4px;
}
.primaryBtn{
  background-color: #274941;
  width: 100%;
  padding: 0;
  margin: 0;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
}

@media (min-width: 1024px) {
  .dashboard-page-container {
    width: 100%;
    max-width: 1200px;
  }
}


@media (max-width: 768px) {
  .dashboard-page-container {
    width: 100%; 
    margin: 0 10px; 
  }

  .recentTransactionsTable{
    width: 100% !important;
    margin: 0 !important;
    margin-bottom: 10px !important;
  }
  .removeOnMobile{
    display: none !important;
  }

  .tableOnMobile{
    overflow-x:auto !important;
    overflow-y: auto !important;
    max-height: 100% !important;
  }
  .dailyTransactionsSection{
    margin-inline: 0 !important;
  }
}

/* @media only screen and (min-width: 768px) and (max-width: 1024px){
  .recentTransactionsTable{
    width: 100% !important;
    margin: 0 !important;
  }
  .dailyTransactionsSection{
    margin-inline: 0 !important;
  }
  .tableOnMobile{
    overflow-x:auto !important;
    overflow-y: auto !important;
    max-height: 100% !important;
  }
  
} */





