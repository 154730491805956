
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.App{
  display: flex;
  justify-content: space-between;
}
*{
  font-family: "Roboto", sans-serif !important;
}

.sideBanner::before {
  content: ''; /* Required for pseudo-elements */
  position: absolute; /* Position it absolutely */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.832); */
  z-index: 1; /* Ensure it's on top of the image */
}
.sideBanner > * {
  position: relative; 
  z-index: 2; 
  color: white; 
  text-align: center;
}

.sideBanner {
  position: relative;
  background-image: url('../assets/authbg2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100dvh; /* Use 'vh' for responsive height */
  min-width: 40rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
}

.loginIntro {
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}
.loginSubText{
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.authContainer{
  height: 100dvh;
  width: 100%; 
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: 
   linear-gradient(310deg, #fcf6ea 40%, #fffffff1 60%),
   url('../assets/authbg2.jpg') no-repeat center center;
   background-size: cover;
   background-attachment: fixed;
   /* margin-bottom: 10px; */
}

.auth{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.formCard {
  width: 100%; 
  max-width: 400px; 
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto; 
  padding: 20px;
  border-radius: 4px;
  /* background-color: #10101099; */
}


  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .sideBanner{
      display: none;
    }
    
  }

  @media screen and (max-width: 767px) {
    .sideBanner{
      display: none;
    }
  }